import React from "react";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";

import ReactTooltip from "react-tooltip";
import "lightgallery.js/dist/css/lightgallery.css";

import './LightGalleryStyle.scss';



const LightGallery = (props) => {
    const PhotoItem = ({ image, group }) => (
            <div className="gallery__image__box">
                <LightgalleryItem group={group} src={image}>
                    <img src={image} className="gallery__image" alt="" />
                </LightgalleryItem>
            </div>
            
        );
    return (  
        <div className="gallery__grid-container" data-tip="Click to view photo">
            <LightgalleryProvider>
                {props.item.map((image, index) => (
                        <div className="box" key={index}>
                        <PhotoItem image={image}/>
                        </div>
                ))}
            </LightgalleryProvider>
            <ReactTooltip place="top" type="dark" effect="float" />
        </div>
    )
}


export default LightGallery

import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';
import LightGallery from "./LightGallery";
import './LightGalleryStyle.scss';


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const getImgURLs = (galleryImageObject) => {
  return galleryImageObject.node.images.map(item => item.file.url) 
}


function TabPanel(props) {
  const {children, value, index} = props
  return <div>{value === index && <p>{children}</p>}</div>
}

export default function GalleryTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const data = useStaticQuery(graphql`
        query {
            allContentfulGallery (sort: { fields: [categoryName], order: ASC }) {
                edges {
                    node {
                        categoryName
                        images {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `)
    
    const [app, drnk, int, main] = data.allContentfulGallery.edges

    // const appetizers = getImgURLs(app)
    const drinks = getImgURLs(drnk)
    const interior = getImgURLs(int)
    const mainDishes = getImgURLs(main)


  return (
    <div className="galleryPage">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
        <Tab label="Main Dishes" />
        {/* <Tab label="Appetizers" /> */}
        <Tab label="Drinks" />
        <Tab label="Interior" />
      </Tabs>
      <div className="galleryPageCarouselContainer">
        <TabPanel value={value} index={0} >
          <LightGallery item={mainDishes}/>
        </TabPanel>

        {/* <TabPanel value={value} index={1}>
          <LightGallery item={appetizers}/>
        </TabPanel> */}

        <TabPanel value={value} index={1}>
          <LightGallery item={drinks}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <LightGallery item={interior}/>
        </TabPanel>
      </div>
    </div>
  );
}

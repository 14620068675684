import React from "react";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";

import GalleryTabs from "../components/GalleryTabs/GalleryTabs";
import '../components/GalleryTabs/LightGalleryStyle.scss';
import Header from "../components/header"
import Parallax from "../components/ParallaxCard"

import Gallery from "../images/hero/Restaurant2.jpg";

const GalleryPage = () => {
  return (
    <>
      <GatsbySeo
        title="SitLo Saigon Gallery"
        description="Our Gallery showcases some of personal favorites and our customers' top choices from our extremely flavorful broth."
        canonical="/gallery"
      />
      <Layout>
        <Header />
        <Parallax
          image={Gallery}
          alt="Gallery"
          height="50vh"
          children={
            <h1>Gallery</h1>
          }
        />
        <GalleryTabs />
      </Layout>
    </>
  )
}

export default GalleryPage